<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline="true"
          :label-position="$config.labelPosition"
          :label-width="$config.labelWidth"
          size="mini"
        >
          <div class="form-label-base">{{nav[0].name}}</div>
          <el-form-item label="所属企业" prop="company">
            <b>{{companyName}}</b>
          </el-form-item>

          <el-form-item label="车队名称" prop="teamName">
            <el-input v-model="form.teamName" placeholder="请输入" maxlength="18"></el-input>
          </el-form-item>

          <el-form-item label="车队负责人" prop="contacts">
            <el-input v-model="form.contacts" placeholder="请输入" maxlength="18"></el-input>
          </el-form-item>

          <el-form-item label="联系电话" prop="contactPhone">
            <el-input v-model="form.contactPhone" placeholder="手机号" maxlength="11"></el-input>
          </el-form-item>

          <el-form-item label="车队业务" prop="teamBusiness">
            <el-input v-model="form.teamBusiness" placeholder="请输入" maxlength="64"></el-input>
          </el-form-item>

          <el-form-item label="所属标段" prop="bidId">
            <ayl-cascader-for-bid v-model="form.bidId" @change="handleBidChage"></ayl-cascader-for-bid>
          </el-form-item>
        </el-form>
      </div>

      <div class="tac" style="margin-top: 40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
        <!-- <el-button class="btn-hw" @click="onSubmitAndNext">保存并录入下一条</el-button> -->
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关 闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import edit from "@/mixins/edit";
export default {
  mixins: [edit],

  data() {
    return {
      debug: 0,
      nav: [{ name: "编辑车队" }],
      company: null,
      companyName: null,
      form: {
        bidId: null,
        contactPhone: null,
        contacts: null,
        groupId: null,
        teamBusiness: null,
        teamName: null
      },
      rules: {
        // company: [
        //   {
        //     min: 2,
        //     max: 25,
        //     required: true,
        //     message: "请输入2-25位企业名称",
        //     trigger: "blur"
        //   },
        //   { validator: this.$validator.isContainBlank }
        // ],
        teamName: [
          {
            min: 2,
            max: 18,
            required: true,
            message: "请输入2-18位车队名称",
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ],
        contacts: [
          {
            max: 18,
            required: true,
            message: "请输入车队负责人",
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ],
        contactPhone: [
          {
            required: true,
            trigger: "blur",
            validator: this.$validator.validatePhone
          }
        ],
        teamBusiness: [
          {
            max: 64,
            required: true,
            message: "请输入车队业务",
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ],
        bidId: { required: true, message: "请选择标段", trigger: "change" }
      }
    };
  },
  methods: {
    async submit() {
      let self = this;
      await self.$api_hw.fleetManagement_update({
        record: _fixData(self.form)
      });
      function _fixData(d) {
        //数据格式转化
        // for (let key in d) {
        //   switch (key) {
        //   }
        // }
        d.company=self.company;
        self.log(d);
        return d;
      }
    },
    async getInfo() {
      await _getData(this, this.$route.query.id);
    }
  },
  async mounted() {
    let companyInfo = await this.$api_hw.common_getTenantCompanyInfo({});
    this.companyName = companyInfo.label;
    this.company = companyInfo.value;
  }
};

async function _getData(self, id) {
  //详情数据
  let formData = await self.$api_hw.fleetManagement_getById({
    id: id
  });

  self.form = _fixData(formData);

  /**
   * 把服务器端的Data 修改一下以方便编辑
   */
  function _fixData(d) {
    // for (let key in d) {
    //   switch (key) {
    //   }
    // }
    self.log(d);
    return d;
  }
}
</script>

<style lang='sass' scoped>
.form-label-base
  border-left: 2px solid rgb(26, 188, 156)
  padding-left: 8px
</style>
